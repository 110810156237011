import React, { forwardRef, useRef, useState, useImperativeHandle } from 'react';
import { Card, CardBody, CardTitle, Badge, Row, Col, FormGroup, Label } from 'reactstrap';
import * as Icon from 'react-feather';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Table, Tbody, Tr, Td } from 'react-super-responsive-table';
import moment from 'moment';
import Datetime from 'react-datetime';

import 'react-datetime/css/react-datetime.css';

import {
  SmallModal,
  WarningModal,
  ConfirmationModal,
  ErrorModal,
  ProcessingModal,
} from '../modals/CustomModals';
import { deleteAsync, postAsync, putAsync } from '../../functions/my-api';
import { generateRandomString } from '../../functions/common';

require('moment/locale/en-gb');

const Milestone = forwardRef((props, ref) => {
  const styles = {
    cell: {
      border: '1px solid #DEE2E6',
      padding: '8px 12px',
      textAlign: 'left',
    },
    row: {
      border: '1px solid #DEE2E6',
      padding: '.25em',
    },
  };

  const validationSchema = Yup.object({
    milestone: Yup.string().required('Milestone is required'),
  });

  const tenderRFIMilestoneModalRef = useRef();
  const requiredFieldsWarningModalRef = useRef();
  const deleteModalConfirmationRef = useRef();
  const errorModalRef = useRef();
  const formikRef = useRef();
  const processingModalRef = useRef();

  const [dueDate, setDueDate] = useState(new Date().toISOString());
  const [milestoneRefreshKey, setMilestoneRefreshKey] = useState(generateRandomString());
  const [selectedMilestoneId, setSelectedMilestoneId] = useState(0);

  const [initialValues, setInitialValues] = useState({
    milestone: props.data?.milestone,
    dueDate: props.data?.dueDate,
    milestoneId: props.data?.milestoneId,
    milestones: props.data ?? [],
  });

  useImperativeHandle(ref, () => ({
    getListOfMilestones() {
      return initialValues.milestones;
    },
  }));

  const handleAddNewMilestone = async () => {
    const validationResult = await formikRef.current.validateForm();

    if (Object.keys(validationResult).length === 0) {
      const inputValues = formikRef.current.values;
      inputValues.milestoneId = !selectedMilestoneId ? generateRandomString() : selectedMilestoneId;
      inputValues.dueDate = dueDate;

      processingModalRef.current.toggleModal();

      let response = {};
      if (selectedMilestoneId) {
        response = await putAsync(
          `${process.env.REACT_APP_TSA_URL}/tender/milestone/${props.parentId}`,
          inputValues,
        );
      } else {
        response = await postAsync(
          `${process.env.REACT_APP_TSA_URL}/tender/milestone/${props.parentId}`,
          inputValues,
        );
      }

      setTimeout(() => {
        processingModalRef.current.toggleModal();

        if (response.status === 200) {
          if (selectedMilestoneId) {
            initialValues.milestones.forEach((item) => {
              if (item.milestoneId === selectedMilestoneId) {
                item.milestoneId = selectedMilestoneId;
                item.milestone = inputValues.milestone;
                item.dueDate = inputValues.dueDate;
              }
            });
          } else {
            initialValues.milestones.push({
              milestoneId: inputValues.milestoneId,
              milestone: inputValues.milestone,
              dueDate: inputValues.dueDate,
            });
          }

          setMilestoneRefreshKey(generateRandomString());
          tenderRFIMilestoneModalRef.current.toggleModal();
        } else {
          errorModalRef.current.toggleModal();
        }
      }, 2000);
    }
  };

  const handleRemoveMilestone = async () => {
    processingModalRef.current.toggleModal();

    const response = await deleteAsync(
      `${process.env.REACT_APP_TSA_URL}/tender/milestone/${props.parentId}/uid/${selectedMilestoneId}`,
    );

    setTimeout(() => {
      processingModalRef.current.toggleModal();

      if (response.status === 200) {
        initialValues.milestones = initialValues.milestones.filter((item) => {
          return item.milestoneId !== selectedMilestoneId;
        });
        setMilestoneRefreshKey(generateRandomString());
        setInitialValues(initialValues);
      } else {
        errorModalRef.current.toggleModal();
      }
    }, 2000);
  };

  const handleEditMilestone = async (id) => {
    setSelectedMilestoneId(id);

    const itemToBeEdited = initialValues.milestones.filter((item) => {
      return item.milestoneId === id;
    });

    formikRef.current.values.milestone = itemToBeEdited[0].milestone;
    formikRef.current.values.dueDate = moment(itemToBeEdited[0].dueDate);
    setDueDate(moment(itemToBeEdited[0].dueDate));

    tenderRFIMilestoneModalRef.current.toggleModal();
  };

  const renderExistingMilestones = () => {
    const existingMilestones = [];

    initialValues.milestones.forEach((e) => {
      existingMilestones.push(
        <Tr key={generateRandomString()} style={styles.row}>
          <Td style={styles.cell}>{e.milestone}</Td>
          <Td style={styles.cell}>{moment(e.dueDate).format('DD/MM/YYYY HH:mm')}</Td>
          <Td style={styles.cell}>
            {/* <span
              className="text-link"
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => {}}
            >
              View
            </span> */}
            &nbsp;&nbsp;&nbsp;
            {props.showAddButton ? (
              <>
                <span
                  className="text-link"
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => {
                    handleEditMilestone(e.milestoneId);
                  }}
                >
                  Edit
                </span>
                &nbsp;&nbsp;&nbsp;
                <span
                  className="text-link"
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => {
                    deleteModalConfirmationRef.current.toggleModal();
                    setSelectedMilestoneId(e.milestoneId);
                  }}
                >
                  Delete
                </span>
              </>
            ) : (
              ''
            )}
          </Td>
        </Tr>,
      );
    });

    return existingMilestones;
  };

  return (
    <Formik
      key={milestoneRefreshKey}
      initialValues={initialValues}
      validationSchema={validationSchema}
      innerRef={formikRef}
      onSubmit={() => {
        // Do nothing. Everything is handled in the handleSubmit function
      }}
    >
      {(formik) => {
        return (
          <Card>
            <CardTitle
              tag="h5"
              className="border-bottom bg-white p-3 mb-0 text-black text-center card-rounded-top"
            >
              <span style={{ float: 'left' }}>
                Tender/RFI Milestones (For example: Mandatory Site visits & Tender lodgement)
              </span>
              <span style={{ float: 'left' }} className="text-danger">
                &nbsp;{props.warningMessage}
              </span>
              {props.showAddButton ? (
                <Badge
                  color="primary"
                  className="ms-3"
                  pill
                  style={{ float: 'right', zIndex: 999, cursor: 'pointer' }}
                  onClick={() => {
                    formik.values.milestone = '';
                    formik.values.dueDate = new Date();

                    setSelectedMilestoneId(0);
                    setDueDate(moment(new Date()));

                    tenderRFIMilestoneModalRef.current.toggleModal();
                  }}
                >
                  Add New Milestone <Icon.FilePlus size={20} />
                </Badge>
              ) : (
                ''
              )}
            </CardTitle>
            <CardBody className="card-rounded-bottom">
              <Table>
                <Tbody>
                  <Tr style={styles.row}>
                    <Td style={styles.cell}>Milestone</Td>
                    <Td style={styles.cell}>Milestone Due Date</Td>
                    {props.showAddButton ? <Td style={styles.cell}>Actions</Td> : ''}
                  </Tr>
                  {renderExistingMilestones()}
                </Tbody>
              </Table>
            </CardBody>
            <SmallModal
              ref={tenderRFIMilestoneModalRef}
              size="xl"
              backdrop="static"
              header="Add New Milestone"
              submitCallBack={() => {
                handleAddNewMilestone();
              }}
              showFooterButtons
              showSubmitButton
              showCancelButton
            >
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Row>
                      <Label sm="3" style={{ textAlign: 'left' }}>
                        Milestones:
                      </Label>
                      <Col sm="9">
                        <Field id="milestone" name="milestone" className="form-control" />
                        {formik.errors.milestone ? (
                          <div
                            style={{ display: 'block', textAlign: 'left' }}
                            className="invalid-feedback"
                          >
                            {formik.errors.milestone}
                          </div>
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Row>
                      <Label sm="3" style={{ textAlign: 'left' }}>
                        Due Date:
                      </Label>
                      <Col sm="9">
                        <Datetime
                          name="dueDate"
                          locale="en-gb"
                          // timeFormat={true}
                          inputProps={{ placeholder: '' }}
                          onChange={(value) => {
                            // eslint-disable-next-line no-underscore-dangle
                            setDueDate(moment(value._d));
                          }}
                          value={moment(dueDate).format('DD/MM/YYYY HH:mm')}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
            </SmallModal>
            <WarningModal ref={requiredFieldsWarningModalRef} />
            <ErrorModal ref={errorModalRef} />
            <ConfirmationModal
              ref={deleteModalConfirmationRef}
              body="Are you sure you want to remove this milestone?"
              confirmText="Delete"
              submitCallBack={handleRemoveMilestone}
            />
            <ProcessingModal ref={processingModalRef} />
          </Card>
        );
      }}
    </Formik>
  );
});

Milestone.propTypes = {
  data: PropTypes.array,
  warningMessage: PropTypes.string,
  showAddButton: PropTypes.bool,
  parentId: PropTypes.number,
};

export default Milestone;
