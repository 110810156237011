import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
import Logout from '../views/widgets/Logout';
/****Layouts*****/

const FullLayoutContractor = Loadable(lazy(() => import('../layouts/FullLayoutContractor')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
/***** Pages ****/

const ContractorDashboard = Loadable(lazy(() => import('../views/dashboards/ContractorDashboard')));

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
const Unauthorized = Loadable(lazy(() => import('../views/auth/Unauthorized')));
const RegisterFormik = Loadable(lazy(() => import('../views/auth/RegisterFormik')));
const Maintanance = Loadable(lazy(() => import('../views/auth/Maintanance')));
const LockScreen = Loadable(lazy(() => import('../views/auth/LockScreen')));
const RecoverPassword = Loadable(lazy(() => import('../views/auth/RecoverPassword')));
const Trainings = Loadable(lazy(() => import('../views/forms/shared/Trainings')));

/***** JETCharge Pages ****/
const ContractorSiteVisitForm = Loadable(
  lazy(() => import('../views/forms/contractor/ContractorSiteVisitForm')),
);
const ContractorTsa = Loadable(lazy(() => import('../views/forms/contractor/ContractorTsa')));
const ContractorDst = Loadable(lazy(() => import('../views/forms/contractor/ContractorDst')));
const ContractorSow = Loadable(lazy(() => import('../views/forms/contractor/ContractorSow')));

/*****Routes******/

const ContractorRoutes = [
  {
    path: '/',
    element: <FullLayoutContractor />,
    children: [
      {
        path: '/contractors/dashboard',
        name: 'Contractor Dashboard',
        exact: true,
        element: <ContractorDashboard />,
      },
      {
        path: '/contractors/tsa',
        name: 'Technical Site Assessment',
        exact: true,
        element: <ContractorTsa />,
      },
      {
        path: '/contractors/Trainings',
        name: 'Trainings',
        exact: true,
        element: <Trainings />,
      },
      {
        path: '/contractors/tsa/:id',
        name: 'Edit Technical Site Assessment',
        element: <ContractorSiteVisitForm />,
      },
      { path: '/contractors/dst', name: 'Document Submission Tool', element: <ContractorDst /> },
      { path: '/contractors/sow/:id', name: 'Edit Statement of Work', element: <ContractorSow /> },
    ],
  },
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: 'auth/404', element: <Error /> },
      { path: 'auth/401', element: <Unauthorized /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
      { path: 'registerformik', element: <RegisterFormik /> },
      { path: 'maintanance', element: <Maintanance /> },
      { path: 'lockscreen', element: <LockScreen /> },
      { path: 'recoverpwd', element: <RecoverPassword /> },
      { path: 'logout', element: <Logout /> },
    ],
  },
];

export default ContractorRoutes;
