import React, { Suspense, useEffect } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LoadScript } from '@react-google-maps/api';
import StaffRoutes from './routes/StaffRoutes';
import ContractorRoutes from './routes/ContractorRoutes';
import UnauthorizedRoutes from './routes/UnauthorizedRoute';

import ThemeSelector from './layouts/theme/ThemeSelector';
import Loader from './layouts/loader/Loader';
import { ResetTimer } from './functions/my-api';
import { getUserType } from './functions/my-user';
import { isValidUrl, normalizeSpaces } from './functions/common';

import './assets/scss/styleOverrides.scss';

const App = () => {
  const contractorRoutes = useRoutes(ContractorRoutes);
  const staffRoutes = useRoutes(StaffRoutes);
  const unauthorizedRoutes = useRoutes(UnauthorizedRoutes);
  const direction = useSelector((state) => state.customizer.isRTL);
  const isMode = useSelector((state) => state.customizer.isDark);

  const { instance, accounts } = useMsal();
  const user = accounts ? accounts[0] : null;

  const libraries = ['places'];

  let routes = null;
  switch (getUserType()) {
    case 'contractor': {
      localStorage.setItem(
        'JETChargeCurrentUser',
        JSON.stringify({
          name:
            user?.name ?? `${user?.idTokenClaims?.given_name} ${user?.idTokenClaims?.family_name}`,
          username: user?.username,
        }),
      );

      // if the user is jetcharge and tried opening a contractor page
      if (
        user &&
        user?.username.includes('jetcharge.com.au') &&
        window.location.pathname !== '/auth/404'
      ) {
        window.location.href = '/auth/404';
      }

      // if the user is not logged in and the path is not /login/contractors, redirect to /login/contractors
      if (
        !user &&
        window.location.pathname !== '/contractors/login' &&
        !window.location.href.includes('passthrough')
      ) {
        window.location.href = `/contractors/login?state=${window.location.href}`;
      }

      // if the user is logged in and the path is /login/contractors, redirect to /contractors/dashboard
      if (
        user &&
        (window.location.pathname === '/contractors/login' || window.location.pathname === '/')
      ) {
        // Create an instance of URLSearchParams
        const params = new URLSearchParams(window.location.search);
        const redirectUrl = params.get('state');
        if (redirectUrl && isValidUrl(redirectUrl)) {
          window.location.href = redirectUrl;
        } else {
          window.location.href = '/contractors/dashboard';
        }
      }

      // if the we get a state it means the sso was successful
      if (window.location.pathname === '/' && window.location.hash.includes('#state')) {
        window.location.href = '/contractors/dashboard';
      }

      routes = contractorRoutes;
      break;
    }
    case 'internal': {
      localStorage.setItem(
        'JETChargeCurrentUser',
        JSON.stringify({ name: normalizeSpaces(user?.name), username: user?.username }),
      );

      // if the user is contractor and tried opening an internal page
      if (
        user &&
        !user?.username.includes('jetcharge.com.au') &&
        window.location.pathname !== '/auth/404'
      ) {
        window.location.href = '/auth/404';
      }

      // if the user is not logged in and the path is not /login/internal, redirect to /login/contractors
      if (
        !user &&
        window.location.pathname !== '/internal/login' &&
        !window.location.href.includes('passthrough')
      ) {
        window.location.href = `/internal/login?state=${window.location.href}`;
      }

      // if the user is logged in and the path is /internal/login, redirect to /internal/dashboard
      if (
        user &&
        (window.location.pathname === '/internal/login' || window.location.pathname === '/')
      ) {
        // Create an instance of URLSearchParams
        const params = new URLSearchParams(window.location.search);
        const redirectUrl = params.get('state');

        if (redirectUrl && isValidUrl(redirectUrl)) {
          window.location.href = redirectUrl;
        } else {
          window.location.href = '/internal/dashboard';
        }
      }

      // if the we get a state it means the sso was successful
      if (window.location.pathname === '/' && window.location.hash.includes('#code')) {
        window.location.href = '/internal/dashboard';
      }

      routes = staffRoutes;
      break;
    }
    case 'customer': {
      localStorage.setItem(
        'JETChargeCurrentUser',
        JSON.stringify({ name: 'customer', username: 'customer@jetcharge.com.au' }),
      );
      break;
    }
    default: {
      window.location.href = '/internal/login';
      break;
    }
  }

  useEffect(() => {
    ResetTimer(instance);
    window.addEventListener('mousemove', () => {
      ResetTimer(instance);
    });
    window.addEventListener('keydown', () => {
      ResetTimer(instance);
    });

    const params = new URLSearchParams(window.location.search);
    const passthroughKey = params.get('passthrough');

    if (passthroughKey === process.env.REACT_APP_MICROSOFT_APP_PASSTHROUGH_KEY && !user) {
      setTimeout(() => {
        instance.loginRedirect();
      }, 100);
    }

    return () => {
      clearTimeout(localStorage.getItem('JETChargeTimeOutId'));
      window.removeEventListener('mousemove', () => {
        ResetTimer(instance);
      });
      window.removeEventListener('keydown', () => {
        ResetTimer(instance);
      });
    };
  }, []);

  if (window.location.href.includes('passthrough')) {
    return <div>Getting User details. Please Wait...</div>; // Or any other loading state representation
  }

  return (
    <Suspense fallback={<Loader />}>
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
        libraries={libraries}
      />
      <AuthenticatedTemplate>
        <div
          className={`${direction ? 'rtl' : 'ltr'} ${isMode ? 'dark' : 'light'}`}
          dir={direction ? 'rtl' : 'ltr'}
        >
          <ThemeSelector />
          {routes}
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <ThemeSelector />
        {unauthorizedRoutes}
      </UnauthenticatedTemplate>
    </Suspense>
  );
};

export default App;
