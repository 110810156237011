import { eachDayOfInterval, isSaturday, isSunday } from 'date-fns';

export const generateRandomString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let result = '';

  const j = length ?? 6;

  for (let i = 0; i < j; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const isValidDate = (dateString) => {
  const date = new Date(dateString);
  return !Number.isNaN(date.getTime());
};

export const sortByKey = (data) => {
  const naturalSort = (a, b) => {
    const ax = [];
    const bx = [];

    a.replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
      ax.push([$1 || Infinity, $2 || '']);
    });
    b.replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
      bx.push([$1 || Infinity, $2 || '']);
    });

    // Compare each piece sequentially
    while (ax.length && bx.length) {
      const an = ax.shift();
      const bn = bx.shift();
      const nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);
      if (nn) return nn;
    }

    // If one array is empty, and the other is not, the non-empty one comes last
    return ax.length - bx.length;
  };

  // Apply the natural sort to the data array
  const sortedData = data.sort(naturalSort);

  return sortedData;
};

export const getWindowSize = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isValidUrl = (string) => {
  try {
    // eslint-disable-next-line no-new
    new URL(string); // Assign to a variable to satisfy the linter
    return true; // URL is valid
  } catch (error) {
    return false; // URL is invalid
  }
};

export const fetchIPAddress = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error('Error fetching IP:', error);
  }

  return null;
};

export const getBusinessDays = (startDate, endDate) => {
  if (!startDate || !endDate) return 0;

  startDate = new Date(startDate);
  endDate = new Date(endDate);

  // Create new dates ignoring the time part
  const start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
  const end = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

  // Make sure start date should be less than or equal to the end date
  if (start > end) return 0;

  // Check if startDate and endDate are the same
  if (start.getTime() === end.getTime()) {
    return 0;
  }

  const days = eachDayOfInterval({
    start: startDate,
    end: endDate,
  });

  const weekends = days.filter(
    (day) => !isSaturday(day) && !isSunday(day) && day.getTime() !== start.getTime(),
  );
  return weekends.length;
};

export const isImage = (filename, mimetype) => {
  let result = false;

  const imageMimeTypes = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/tiff',
    'image/webp',
    'image/svg+xml',
    'image/heic',
    'image/heif',
  ];
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp', 'svg'];

  if (imageMimeTypes.includes((mimetype || '').toLowerCase())) {
    result = true;
  }

  if (imageExtensions.includes((filename || '').split('.').pop().toLowerCase())) {
    result = true;
  }

  return result;
};

export const normalizeSpaces = (name) => {
  // Replace multiple spaces with a single space and trim leading/trailing spaces
  if (name) return name.replace(/\s+/g, ' ').trim();
  return '';
};

export const getElementSize = (elementid) => {
  const element = document.getElementById(elementid);
  const width = element.offsetWidth;

  // Determine the sizes based on the container width
  if (width <= 576) {
    return 'small';
  }

  if (width > 576 && width <= 768) {
    return 'medium';
  }

  if (width > 768) {
    return 'large';
  }

  return '';
};

export const calculateMedian = (numbers) => {
  if (numbers.length === 0) return 0;

  const sorted = numbers.slice().sort((a, b) => a - b);
  const mid = Math.floor(sorted.length / 2);

  const median = sorted.length % 2 !== 0 ? sorted[mid] : (sorted[mid - 1] + sorted[mid]) / 2;

  return median;
};

export const calculateAverage = (sum, count) => {
  if (count === 0) return 0;
  return sum / count;
};

export const getElementHeight = (elementId) => {
  const element = document.getElementById(elementId);
  if (!element) return 0;

  const style = window.getComputedStyle(element);

  // Get the element's padding
  const paddingTop = parseFloat(style.paddingTop);
  const paddingBottom = parseFloat(style.paddingBottom);

  // Get the clientHeight (height with padding, but without border and scrollbar)
  const height = element.clientHeight;

  // Calculate the height without padding
  const heightWithoutPadding = height - paddingTop - paddingBottom;
  return heightWithoutPadding;
};

class UtilityClass {}

export default UtilityClass;
