import { useRef, useImperativeHandle, forwardRef } from 'react';
import { Card, CardTitle, CardBody, Row, Col, Label } from 'reactstrap';

const PrivacyPolicy = forwardRef((props, ref) => {
  const customerAcceptanceRef = useRef();

  const handleCustomerAcceptanceCheckChange = (e) => {
    if (!e.target.checked) {
      document.getElementById('csiNextButton').style.display = 'none';
    } else {
      document.getElementById('csiNextButton').style.display = 'block';
    }
  };

  useImperativeHandle(ref, () => ({
    getCustomerAcceptanceCheck() {
      return customerAcceptanceRef.current.checked;
    },
  }));

  return (
    <Card>
      <CardTitle
        tag="h5"
        className="border-bottom bg-white p-3 mb-0 text-black text-center card-rounded-top"
      ></CardTitle>
      <CardBody className="card-rounded-bottom">
        <Row>
          <Col md="12">
            <h5>
              <strong>
                Please confirm that you agree to JET Charge collecting, using & disclosing any
                personal information in accordance with our privacy policy available at{' '}
                <a
                  href="https://jetcharge.com.au/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy - JET Charge.
                </a>
              </strong>
            </h5>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <div style={{ textAlign: 'left' }}>
              <input
                ref={customerAcceptanceRef}
                type="checkbox"
                className="form-check-input"
                name="CustomerAcceptanceCheck"
                onChange={(e) => {
                  handleCustomerAcceptanceCheckChange(e);
                }}
              />
              &nbsp;&nbsp;&nbsp;
              <Label>
                <strong>I accept</strong>
              </Label>
            </div>
            <Label
              id="customerAcceptanceCheckWarning"
              style={{ textAlign: 'left' }}
              className="d-none text-danger"
            >
              <strong>Required</strong>
            </Label>
          </Col>
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Col md="12">
            <h5
              className="hidePrint text-left mb-2 paddingLeftVariable"
              style={{ display: 'block' }}
              color="muted"
            >
              <strong>
                Please confirm the following information is correct, change any incorrect details,
                and hit next.
              </strong>
            </h5>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
});

export default PrivacyPolicy;
