import { postAsync } from './my-api';

const Base64ToFile = async ({ base64String, fileName, mimeType }) => {
  // Step 1: Decode Base64 string to binary data
  const binaryString = window.atob(base64String);

  // Step 2: Create a Blob from binary data
  const arrayBuffer = new ArrayBuffer(binaryString.length);
  const uintArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < binaryString.length; i++) {
    uintArray[i] = binaryString.charCodeAt(i);
  }

  const blob = new Blob([uintArray], { type: mimeType });

  // Step 3: Create a File object from the Blob
  const file = new File([blob], fileName, { type: mimeType });

  return file;
};

const FileToBase64 = async (file) => {
  // create code that converts file to base64 string
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

const UploadFileByPayload = async (path, payload) => {
  // Perform the file upload using fetch or any other library (e.g., Axios)
  const response = await postAsync(path, payload);
  return response;
};

const DownloadFile = async ({ base64String, fileName, mimeType }) => {
  const file = await Base64ToFile({ base64String, fileName, mimeType });

  // Step 4: Create a temporary URL for the File object
  const fileUrl = URL.createObjectURL(file);

  // Step 5: Create a download link and click it programmatically
  const downloadLink = document.createElement('a');
  downloadLink.href = fileUrl;
  downloadLink.download = file.name;
  downloadLink.click();

  // Clean up the temporary URL after the download link is clicked
  URL.revokeObjectURL(fileUrl);
};

const ViewFile = async ({ base64String, fileName, mimeType }) => {
  const file = await Base64ToFile({ base64String, fileName, mimeType });

  // Step 4: Create a temporary URL for the File object
  const fileUrl = URL.createObjectURL(file);

  // Step 5: Open the file in a new tab
  window.open(fileUrl, '_blank');

  // Clean up the temporary URL after opening the new tab
  URL.revokeObjectURL(fileUrl);
};

const Base64ToBlob = (base64String, mimeType) => {
  // Convert Base64 to Blob
  const byteCharacters = atob(base64String);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: mimeType }); // Specify the MIME type

  // Now you have the Blob object
  return blob;
};

const BufferToBlob = (buffer, mimeType) => {
  const uint8Array = new Uint8Array(buffer);
  const data = new Blob([uint8Array], { type: mimeType });

  return data;
};

const GetFileSizeFromBase64 = (base64String) => {
  // Remove the data URL prefix (e.g., "data:image/jpeg;base64,")
  const base64WithoutPrefix = base64String.replace(/^data:[^,]+,/, '');

  // Decode the base64 string to binary data
  const binaryData = atob(base64WithoutPrefix);

  // Calculate the size in bytes
  const fileSizeInBytes = binaryData.length;

  return fileSizeInBytes;
};

export {
  Base64ToFile,
  UploadFileByPayload,
  DownloadFile,
  ViewFile,
  FileToBase64,
  Base64ToBlob,
  GetFileSizeFromBase64,
  BufferToBlob,
};
