import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import { Card, CardBody, CardTitle, Row, Col, FormGroup, Label } from 'reactstrap';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

const TenderDetails = forwardRef((props, ref) => {
  const formikRef = useRef();
  const tenderValues = props?.data ? props?.data : {};

  const initialValues = {
    TenderProjectTitle: tenderValues.tenderProjectTitle,
  };

  const validationSchema = Yup.object({
    TenderProjectTitle: Yup.string().required('Tender Project Title is required'),
  });

  useImperativeHandle(ref, () => ({
    async validateForm() {
      const response = await formikRef.current.validateForm();
      return response;
    },
    getInputValues() {
      return formikRef.current.values;
    },
  }));

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      innerRef={formikRef}
      onSubmit={() => {
        /* Do nothing. Everything is handled in the handleSubmit function */
      }}
    >
      {(formik) => (
        <Card>
          <CardTitle
            tag="h5"
            className="border-bottom bg-white p-3 mb-0 text-black text-center card-rounded-top"
          >
            <span style={{ float: 'left' }}>Tender Details</span>
          </CardTitle>
          <CardBody className="card-rounded-bottom">
            <Row>
              <Col md="6" style={{ textAlign: 'left' }}>
                <FormGroup>
                  <Label htmlFor="TenderProjectTitle">Tender Project Title</Label>
                  <Field name="TenderProjectTitle" className="form-control" maxLength="100" />
                  {formik.errors.TenderProjectTitle && (
                    <div
                      style={{ display: 'block', textAlign: 'left' }}
                      className="invalid-feedback"
                    >
                      {formik.errors.TenderProjectTitle}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
    </Formik>
  );
});

TenderDetails.propTypes = {
  data: PropTypes.object,
};

export default TenderDetails;
