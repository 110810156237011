import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Card, CardBody, CardTitle, Row, Col, FormGroup, Label, Input, Collapse } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
// import { useMsal } from '@azure/msal-react';
import Enumerable from 'linq';
import * as Icon from 'react-feather';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

// import { putAsync } from '../../../functions/my-api';
// import htmlToPdf from '../../../functions/my-pdf';
import { ProcessingModal, SuccessModal, ErrorModal } from '../../modals/CustomModals';
import UppyFileUploader from '../../../layouts/uppy/uppy';

const EngineeringUploadForm3 = forwardRef((props, ref) => {
  // const styles = {
  //   cell: {
  //     border: '1px solid #DEE2E6',
  //     padding: '8px 12px',
  //     textAlign: 'left'
  //   },
  //   row: {
  //     border: '1px solid #DEE2E6',
  //     padding: '.25em',
  //   }
  // }

  const processingModalRef = React.useRef();
  const successModalRef = React.useRef();
  const errorModalRef = React.useRef();
  const engineeringDesignFilesRef = React.useRef();
  const supplyAuthorityDocumentationFilesRef = React.useRef();
  const dataLoggingOutputFilesRef = React.useRef();

  const [collapse, setCollapse] = React.useState(props.isOpen ?? true);
  const [contractorMode] = useState(window.location.pathname.includes('/contractors/'));
  const [readOnly] = useState(window.location.pathname.includes('/view'));

  let globalFormik = {};

  const initialValues = {
    engineeringDesign: props?.initialValues?.Questions?.engineeringDesign,
    supplyAuthorityDocumentation: props?.initialValues?.Questions?.supplyAuthorityDocumentation,
    dataLoggingOutput: props?.initialValues?.Questions?.dataLoggingOutput,
  };

  const validationSchema = Yup.object().shape({
    // engineeringDesign: Yup.string().required('Please select an option'),
    // supplyAuthorityDocumentation: Yup.string().required('Please select an option'),
    // dataLoggingOutput: Yup.string().required('Please select an option'),
    engineeringDesignFiles: Yup.string().when('engineeringDesign', {
      is: (value) => value === 'required' && engineeringDesignFilesRef.current.isEmpty(), // Check if value is not 'yes'
      then: Yup.string().required('Engineering Design is required'),
      otherwise: Yup.string(),
    }),
    supplyAuthorityDocumentationFiles: Yup.string().when('supplyAuthorityDocumentation', {
      is: (value) =>
        value === 'required' &&
        !contractorMode &&
        supplyAuthorityDocumentationFilesRef.current.isEmpty(), // Check if value is not 'yes'
      then: Yup.string().required('Supply Authority Documentation is required'),
      otherwise: Yup.string(),
    }),
    dataLoggingOutputFiles: Yup.string().when('dataLoggingOutput', {
      is: (value) =>
        value === 'required' && !contractorMode && dataLoggingOutputFilesRef.current.isEmpty(), // Check if value is not 'yes'
      then: Yup.string().required('Data Logging Output is required'),
      otherwise: Yup.string(),
    }),
  });

  const validateForm = async () => {
    const validationResponse = await globalFormik.validateForm();
    return validationResponse;
  };

  useImperativeHandle(ref, () => ({
    // async clickSubmitButton(_status, _subStatus) {
    //   const response = await saveForm(_status, _subStatus);
    //   return response;
    // },
    async validateForm() {
      const response = await validateForm();
      return response;
    },
    async getFormikValues() {
      const values = await globalFormik.values;
      return values;
    },
  }));

  const toggleCollapse = () => {
    setCollapse(!collapse);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={() => {
          // Do nothing. Everything is handled in the handleSubmit function
        }}
      >
        {
          //eslint-disable-next-line no-unused-vars
          (formik) => {
            globalFormik = formik;
            return (
              <>
                <Row>
                  <Col md="12">
                    <Card>
                      <CardTitle
                        tag="h5"
                        className="border-bottom bg-white p-3 mb-0 text-black text-center"
                        onClick={toggleCollapse.bind(null)}
                      >
                        <span style={{ float: 'left' }}>Documents from Engineering Services</span>
                        {collapse ? (
                          <Icon.Minus size={30} style={{ float: 'right' }} />
                        ) : (
                          <Icon.Plus size={30} style={{ float: 'right' }} />
                        )}
                      </CardTitle>
                      <Collapse isOpen={collapse}>
                        <CardBody>
                          {!contractorMode ? (
                            <>
                              <Row>
                                <Col md="2">Data Logging Output</Col>
                                <Col md="2">
                                  <FormGroup>
                                    <FormGroup check inline>
                                      <Input
                                        type="radio"
                                        value="required"
                                        name="dataLoggingOutput"
                                        checked={formik.values.dataLoggingOutput === 'required'}
                                        onChange={(e) => {
                                          formik.handleChange(e);
                                          formik.values.dataLoggingOutput = e.target.value;
                                          props.selectionChangeCallback();
                                        }}
                                        disabled={readOnly}
                                      />
                                      <Label check>Required</Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                      <Input
                                        type="radio"
                                        value="na"
                                        name="dataLoggingOutput"
                                        checked={formik.values.dataLoggingOutput === 'na'}
                                        onChange={(e) => {
                                          formik.handleChange(e);
                                          formik.values.dataLoggingOutput = e.target.value;
                                          props.selectionChangeCallback();
                                        }}
                                        disabled={readOnly}
                                      />
                                      <Label check>Not Applicable</Label>
                                    </FormGroup>
                                  </FormGroup>
                                  {formik.errors.dataLoggingOutput ? (
                                    <div
                                      style={{ display: 'block', textAlign: 'left' }}
                                      className="invalid-feedback"
                                    >
                                      {formik.errors.dataLoggingOutput}
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col md="12">
                                  <UppyFileUploader
                                    id="dataLoggingOutputFiles"
                                    uploadUrl={props.fileUploadUrl}
                                    downloadUrl={props.fileDownloadUrl}
                                    existingFiles={Enumerable.from(
                                      props.initialValues?.Photos ?? [],
                                    )
                                      .where((x) => x.PhotoFor === 'dataLoggingOutputFiles')
                                      .toArray()}
                                    description="Data Logging Output"
                                    ref={dataLoggingOutputFilesRef}
                                    uploadCompleteCallback={(e) => {
                                      props.uploadCompleteCallback(e);
                                    }}
                                    disabled={readOnly}
                                  />
                                  {formik.errors.dataLoggingOutputFiles ? (
                                    <div
                                      style={{ display: 'block', textAlign: 'left' }}
                                      className="invalid-feedback"
                                    >
                                      {formik.errors.dataLoggingOutputFiles}
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </Col>
                              </Row>
                              &nbsp;
                              <hr />
                            </>
                          ) : (
                            ''
                          )}
                          {!contractorMode ? (
                            <>
                              <Row>
                                <Col md="2">Supply Authority Documentation</Col>
                                <Col md="2">
                                  <FormGroup>
                                    <FormGroup check inline>
                                      <Input
                                        type="radio"
                                        value="required"
                                        name="supplyAuthorityDocumentation"
                                        checked={
                                          formik.values.supplyAuthorityDocumentation === 'required'
                                        }
                                        onChange={(e) => {
                                          formik.handleChange(e);
                                          formik.values.supplyAuthorityDocumentation =
                                            e.target.value;
                                          props.selectionChangeCallback();
                                        }}
                                        disabled={readOnly}
                                      />
                                      <Label check>Required</Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                      <Input
                                        type="radio"
                                        value="na"
                                        name="supplyAuthorityDocumentation"
                                        checked={
                                          formik.values.supplyAuthorityDocumentation === 'na'
                                        }
                                        onChange={(e) => {
                                          formik.handleChange(e);
                                          formik.values.supplyAuthorityDocumentation =
                                            e.target.value;
                                          props.selectionChangeCallback();
                                        }}
                                        disabled={readOnly}
                                      />
                                      <Label check>Not Applicable</Label>
                                    </FormGroup>
                                  </FormGroup>
                                  {formik.errors.supplyAuthorityDocumentation ? (
                                    <div
                                      style={{ display: 'block', textAlign: 'left' }}
                                      className="invalid-feedback"
                                    >
                                      {formik.errors.supplyAuthorityDocumentation}
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col md="12">
                                  <UppyFileUploader
                                    id="supplyAuthorityDocumentationFiles"
                                    uploadUrl={props.fileUploadUrl}
                                    downloadUrl={props.fileDownloadUrl}
                                    existingFiles={Enumerable.from(
                                      props.initialValues?.Photos ?? [],
                                    )
                                      .where(
                                        (x) => x.PhotoFor === 'supplyAuthorityDocumentationFiles',
                                      )
                                      .toArray()}
                                    description="Supply Authority Documentation"
                                    ref={supplyAuthorityDocumentationFilesRef}
                                    uploadCompleteCallback={(e) => {
                                      props.uploadCompleteCallback(e);
                                    }}
                                    disabled={readOnly}
                                  />
                                  {formik.errors.supplyAuthorityDocumentationFiles ? (
                                    <div
                                      style={{ display: 'block', textAlign: 'left' }}
                                      className="invalid-feedback"
                                    >
                                      {formik.errors.supplyAuthorityDocumentationFiles}
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </Col>
                              </Row>
                              &nbsp;
                              <hr />
                            </>
                          ) : (
                            ''
                          )}
                          <Row>
                            <Col md="2">Engineering Design</Col>
                            <Col md="2">
                              <FormGroup>
                                <FormGroup check inline>
                                  <Input
                                    type="radio"
                                    value="required"
                                    disabled={contractorMode || readOnly}
                                    name="engineeringDesign"
                                    checked={formik.values.engineeringDesign === 'required'}
                                    onChange={(e) => {
                                      formik.handleChange(e);
                                      formik.values.engineeringDesign = e.target.value;
                                      props.selectionChangeCallback(e);
                                    }}
                                  />
                                  <Label check>Required</Label>
                                </FormGroup>
                                <FormGroup check inline>
                                  <Input
                                    type="radio"
                                    value="na"
                                    disabled={contractorMode || readOnly}
                                    name="engineeringDesign"
                                    checked={formik.values.engineeringDesign === 'na'}
                                    onChange={(e) => {
                                      formik.handleChange(e);
                                      formik.values.engineeringDesign = e.target.value;
                                      props.selectionChangeCallback(e);
                                    }}
                                  />
                                  <Label check>Not Applicable</Label>
                                </FormGroup>
                              </FormGroup>
                              {formik.errors.engineeringDesign ? (
                                <div
                                  style={{ display: 'block', textAlign: 'left' }}
                                  className="invalid-feedback"
                                >
                                  {formik.errors.engineeringDesign}
                                </div>
                              ) : (
                                ''
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <UppyFileUploader
                                id="engineeringDesignFiles"
                                uploadUrl={props.fileUploadUrl}
                                downloadUrl={props.fileDownloadUrl}
                                existingFiles={Enumerable.from(props.initialValues?.Photos ?? [])
                                  .where((x) => x.PhotoFor === 'engineeringDesignFiles')
                                  .toArray()}
                                description="Engineering Design"
                                ref={engineeringDesignFilesRef}
                                uploadCompleteCallback={(e) => {
                                  props.uploadCompleteCallback(e);
                                }}
                                disabled={contractorMode || readOnly}
                              />
                              {formik.errors.engineeringDesignFiles ? (
                                <div
                                  style={{ display: 'block', textAlign: 'left' }}
                                  className="invalid-feedback"
                                >
                                  {formik.errors.engineeringDesignFiles}
                                </div>
                              ) : (
                                ''
                              )}
                            </Col>
                          </Row>
                        </CardBody>
                      </Collapse>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="6">
                    <ProcessingModal ref={processingModalRef} />
                  </Col>
                  <Col xs="12" md="6">
                    <SuccessModal ref={successModalRef} />
                  </Col>
                  <Col xs="12" md="6">
                    <ErrorModal ref={errorModalRef} />
                  </Col>
                </Row>
              </>
            );
          }
        }
      </Formik>
    </div>
  );
});

EngineeringUploadForm3.propTypes = {
  initialValues: PropTypes.object,
  fileUploadUrl: PropTypes.string,
  fileDownloadUrl: PropTypes.string,
  Questions: PropTypes.object,
  uploadCompleteCallback: PropTypes.func,
  isOpen: PropTypes.bool,
  selectionChangeCallback: PropTypes.func,
};

export default EngineeringUploadForm3;
