import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
import Logout from '../views/widgets/Logout';
import TenderForm from '../views/forms/internal/TenderForm';
/****Layouts*****/

const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
/***** Pages ****/

const StaffDashboard = Loadable(lazy(() => import('../views/dashboards/StaffDashboard')));

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
const RegisterFormik = Loadable(lazy(() => import('../views/auth/RegisterFormik')));
const Maintanance = Loadable(lazy(() => import('../views/auth/Maintanance')));
const LockScreen = Loadable(lazy(() => import('../views/auth/LockScreen')));
const RecoverPassword = Loadable(lazy(() => import('../views/auth/RecoverPassword')));

/***** JETCharge Pages ****/
const DST = Loadable(lazy(() => import('../views/forms/internal/Dst')));
const Trainings = Loadable(lazy(() => import('../views/forms/shared/Trainings')));
const QMS = Loadable(lazy(() => import('../views/forms/shared/Qms')));
const TSA = Loadable(lazy(() => import('../views/forms/internal/Tsa')));
const StatementOfWork = Loadable(lazy(() => import('../views/forms/internal/Sow')));
const IndicativeScopeOfWorksForm = Loadable(lazy(() => import('../views/forms/shared/IswForm')));
const SiteVisitForm = Loadable(lazy(() => import('../views/forms/internal/SiteVisitForm')));

/*****Routes******/

const StaffRoutes = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      {
        path: '/internal/dashboard',
        name: 'StaffDashboard',
        exact: true,
        element: <StaffDashboard />,
      },
      {
        path: '/internal/Trainings',
        name: 'Trainings',
        exact: true,
        element: <Trainings />,
      },
      // Custom Paths
      { path: '/internal/dst', name: 'Document Submission Tool', exact: true, element: <DST /> },
      {
        path: '/internal/tsa',
        name: 'Technical Assessment Collaboration Tool',
        exact: true,
        element: <TSA />,
      },
      { path: '/internal/qms', name: 'Quality Management System', exact: true, element: <QMS /> },
      {
        path: '/internal/sow',
        name: 'Statement of Work',
        exact: true,
        element: <StatementOfWork />,
      },
      {
        path: '/internal/isw/',
        name: 'New Indicative Scope of Works Form',
        exact: true,
        element: <IndicativeScopeOfWorksForm />,
      },
      {
        path: '/internal/isw/:id/view/',
        name: 'View Indicative Scope of Works',
        element: <IndicativeScopeOfWorksForm />,
      },
      {
        path: '/internal/isw/:id/edit/',
        name: 'Edit Indicative Scope of Works',
        element: <IndicativeScopeOfWorksForm />,
      },
      {
        path: '/internal/tsa/:id/view',
        name: 'View Technical Assessment',
        element: <SiteVisitForm />,
      },
      {
        path: '/internal/tsa/:id/edit',
        name: 'Edit Technical Assessment',
        element: <SiteVisitForm />,
      },
      {
        path: '/internal/sow/:id/view',
        name: 'View Statement of Work',
        element: <StatementOfWork />,
      },
      {
        path: '/internal/sow/:id/edit',
        name: 'Edit Statement of Work',
        element: <StatementOfWork />,
      },
      {
        path: '/internal/tender/:id/edit',
        name: 'Edit Tender',
        element: <TenderForm />,
      },
      {
        path: '/internal/tender/:id/view',
        name: 'View Tender',
        element: <TenderForm />,
      },
    ],
  },
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '/auth/404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
      { path: 'registerformik', element: <RegisterFormik /> },
      { path: 'maintanance', element: <Maintanance /> },
      { path: 'lockscreen', element: <LockScreen /> },
      { path: 'recoverpwd', element: <RecoverPassword /> },
      { path: 'logout', element: <Logout /> },
    ],
  },
];

export default StaffRoutes;
