import jsrsasign, { KEYUTIL, KJUR } from 'jsrsasign';
//  import { aesjs } from 'aes-js';
import * as CryptoJS from 'crypto-js';
import raw from '../ssl/api_public_key.pem';
import { getUserType } from './my-user';
import { generateRandomString } from './common';

// // Generate RSA key pair
// const rsaKeyPair = KEYUTIL.generateKeypair('RSA', 2048);
// console.log(rsaKeyPair);

// // Convert public key object to PEM string
// const publicKeyPem2 = KEYUTIL.getPEM(rsaKeyPair.pubKeyObj);
// console.log("publicKeyPem2")
// console.log(publicKeyPem2);

// const privateKeyPem2 = KEYUTIL.getPEM(rsaKeyPair.prvKeyObj, 'PKCS8PRV');
// console.log("privateKeyPem2")
// console.log(privateKeyPem2)

// const anonymousUrls = ['/contractors/statementofwork/']

// Only supports short strings
export const encryptDataInRSA = async (payload) => {
  try {
    const response = await fetch(raw);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const text = await response.text();
    const publicKeyPem = text;
    const publicKeyObj = KEYUTIL.getKey(publicKeyPem);

    const dataToEncrypt = payload;
    const encryptedData = jsrsasign.crypto.Cipher.encrypt(dataToEncrypt, publicKeyObj);
    const encryptedDataBase64 = jsrsasign.hextob64(encryptedData);

    // const requestData = {
    //     encryptedData: encryptedDataBase64,
    //     // signature: signatureBase64
    // };

    return encryptedDataBase64;
  } catch (error) {
    console.error('Error while encrypting data:', error);
    throw error;
  }
};

// export const generateRandomKey = async (length) => {
//     const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//     let key = '';

//     for (let i = 0; i < length; i++) {
//         const randomIndex = Math.floor(Math.random() * characters.length);
//         key += characters.charAt(randomIndex);
//     }

//     return key;
// }

// crypto-js
const encryptDataInAES = async (payload) => {
  try {
    const encrypted = CryptoJS.AES.encrypt(payload, process.env.REACT_APP_AES_KEY);

    // const decrypted = CryptoJS.AES.decrypt(encrypted.toString(), process.env.REACT_APP_AES_KEY);
    // console.log(decrypted.toString(CryptoJS.enc.Utf8));

    return encrypted.toString();
  } catch (error) {
    console.error('Error while encrypting data:', error);
    throw error;
  }
};

export const generateAccessToken = async () => {
  let currentUser = null;

  if (localStorage.getItem('JETChargeCurrentUser')) {
    currentUser = JSON.parse(localStorage.getItem('JETChargeCurrentUser'));
  }
  // else if (window.location.pathname.includes(anonymousUrls)) {
  //     currentUser = { username: "anonymous@jetcharge.com.au" }
  // }
  // else {
  //     // Navigate to login page
  //     window.location.href = "/";
  // }

  const encryptedUsername = await encryptDataInRSA(currentUser.username);

  const oHeader = { alg: 'HS256', typ: 'JWT' };
  const oPayload = {};
  const tNow = KJUR.jws.IntDate.get('now');
  const tEnd = KJUR.jws.IntDate.get('now + 1day');
  oPayload.iss = process.env.REACT_APP_AZURE_HOME_URL;
  oPayload.sub = encryptedUsername;
  // oPayload.nbf = KJUR.jws.IntDate.get('now') - (3 * 60000);
  oPayload.iat = tNow;
  oPayload.exp = tEnd;
  oPayload.jti = generateRandomString(16);

  const sHeader = JSON.stringify(oHeader);
  const sPayload = JSON.stringify(oPayload);
  const sJWT = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, process.env.REACT_APP_JWT_SECRET_KEY);

  return sJWT;
};

export const postAsync = async (url, body) => {
  let response = {};
  const jwt = await generateAccessToken();
  const encryptedBody = await encryptDataInAES(JSON.stringify(body));

  const requestData = {
    encryptedData: encryptedBody,
  };

  await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(requestData),
  })
    .then((serverResponse) => {
      response = serverResponse;
      return serverResponse.json();
    })
    .then((data) => {
      response.data = data;
    })
    .catch((error) => {
      response = error;
      console.error(error);
    });

  return response;
};

export const putAsync = async (url, body) => {
  let response = {};
  const jwt = await generateAccessToken();
  const encryptedBody = await encryptDataInAES(JSON.stringify(body));

  const requestData = {
    encryptedData: encryptedBody,
  };

  await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(requestData),
  })
    .then((serverResponse) => {
      response = serverResponse;
      return serverResponse.json();
    })
    .then((data) => {
      response.data = data;
    })
    .catch((error) => {
      response = error;
      console.error(error);
    });

  return response;
};

export const getAsync = async (url) => {
  let response = {};
  const jwt = await generateAccessToken();

  await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  })
    .then((serverResponse) => {
      response = serverResponse;
      return serverResponse.json();
    })
    .then((data) => {
      console.log(data);
      response.data = data;
    })
    .catch((error) => {
      response = error;
      console.error(error);
    });

  return response;
};

export const deleteAsync = async (url) => {
  let response = {};
  const jwt = await generateAccessToken();

  await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  })
    .then((serverResponse) => {
      response = serverResponse;
      return serverResponse.json();
    })
    .then((data) => {
      console.log(data);
      response.data = data;
    })
    .catch((error) => {
      response = error;
      console.error(error);
    });

  return response;
};

export const postAsync3rdParty = async (url, body) => {
  let response = {};

  await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then((serverResponse) => {
      response = serverResponse;
      return serverResponse.json();
    })
    .then((data) => {
      response.data = data;
    })
    .catch((error) => {
      response = error;
      console.error(error);

      alert('An error just occured. Please try again.');
    });

  return response;
};

export const HandleLogout = (instance) => {
  const keysToRemove = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);

    if (key.toLowerCase().startsWith('jetcharge')) {
      keysToRemove.push(key);
    }
  }

  // Remove keys from localStorage
  keysToRemove.forEach((key) => {
    localStorage.removeItem(key);
  });

  instance.logout();

  //setTimeout(() => {
  switch (getUserType()) {
    case 'contractor': {
      window.location.href = '/login/contractors';
      break;
    }
    case 'internal': {
      window.location.href = '/internal/login';
      break;
    }
    default: {
      window.location.href = '/internal/login';
      break;
    }
  }
  //}, 10000);
};

export const ResetTimer = async (instance) => {
  const sessionTimeout = 60 * 60 * 1000; // 30 minutes X 60 seconds X 1000 milliseconds
  clearTimeout(localStorage.getItem('JETChargeTimeOutId'));

  const timeoutId = setTimeout(() => {
    HandleLogout(instance);
  }, sessionTimeout);
  localStorage.setItem('JETChargeTimeOutId', timeoutId);
};
