import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
/****Layouts*****/

const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
const RegisterFormik = Loadable(lazy(() => import('../views/auth/RegisterFormik')));
const LoginStaff = Loadable(lazy(() => import('../views/auth/LoginStaff')));
const LoginContractor = Loadable(lazy(() => import('../views/auth/LoginContractor')));
const Maintanance = Loadable(lazy(() => import('../views/auth/Maintanance')));
const LockScreen = Loadable(lazy(() => import('../views/auth/LockScreen')));
const RecoverPassword = Loadable(lazy(() => import('../views/auth/RecoverPassword')));
const CustomerSiteInformationForm = Loadable(
  lazy(() => import('../views/forms/shared/CustomerSiteInformationForm')),
);
/*****Routes******/

const UnauthorizedRoutes = [
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: 'internal/login', element: <LoginStaff /> },
      { path: 'contractors/login', name: 'Contractor Login', element: <LoginContractor /> },
      { path: '404', name: 'Staff Login', element: <Error /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: 'register', element: <RegisterFormik /> },
      { path: 'maintanance', element: <Maintanance /> },
      { path: 'lockscreen', element: <LockScreen /> },
      { path: 'recoverpwd', element: <RecoverPassword /> },
      { path: 'customers/isw/:id/', element: <CustomerSiteInformationForm /> },
    ],
  },
];

export default UnauthorizedRoutes;
