import React, { useRef } from 'react';
import { StandaloneSearchBox } from '@react-google-maps/api';
import PropTypes from 'prop-types';

const GooglePlacesAutocomplete = (props) => {
  const inputRef = useRef();

  const handleInputChanged = () => {
    if (props.callBack) {
      props.callBack({ state: undefined }); // No place selected, set state to undefined
    }
  };

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      // Loop through the address components
      const addressComponents = place.address_components;
      const response = {
        address: null,
        state: null,
        country: null,
        postalCode: null,
      };

      addressComponents.forEach((element) => {
        const componentType = element.types;

        if (componentType.includes('administrative_area_level_1')) {
          // This is the state component
          response.state = element.short_name; // or component.short_name for the state abbreviation
        }
      });

      if (props.callBack) {
        props.callBack(response);
      }
    }
  };

  return (
    <StandaloneSearchBox
      onLoad={(ref) => {
        inputRef.current = ref;
      }}
      onPlacesChanged={handlePlaceChanged}
    >
      <input
        id={props.id}
        type="text"
        className="form-control"
        placeholder="Enter Location"
        name={props.name}
        defaultValue={props.initialValue}
        onChange={handleInputChanged}
      />
    </StandaloneSearchBox>
  );
};

GooglePlacesAutocomplete.propTypes = {
  id: PropTypes.string,
  initialValue: PropTypes.string,
  name: PropTypes.string,
  callBack: PropTypes.func,
};

export default GooglePlacesAutocomplete;
