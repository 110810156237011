import React from 'react';
// import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { HandleLogout } from '../../functions/my-api';

const Logout = () => {
  // const navigate = useNavigate();
  const { instance } = useMsal();

  React.useEffect(() => {
    // instance.logoutPopup().then(() => {
    //     // Redirect to login or another page after successful logout
    //     navigate('/login');
    // }).catch((error) => {
    //     console.error('Logout failed', error);
    //     // Handle logout error
    // });
    console.log('Handle Logout from Logout.jsx');
    HandleLogout(instance);
  }, []);

  // return <div>Logging out...</div>;
  // console.log("Handle Logout from Logout.jsx");
  // HandleLogout(instance);
};

export default Logout;
