import { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';

const TooltipItem = (props) => {
  const { placement, target } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      <Tooltip placement={placement} isOpen={tooltipOpen} target={target} toggle={toggle}>
        {props.children}
      </Tooltip>
    </span>
  );
};

TooltipItem.propTypes = {
  placement: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.object,
};

export default TooltipItem;
