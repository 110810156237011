import utf8 from 'utf8';
import { postAsync3rdParty } from './my-api';

// // FOR DEBUGGING PURPOSES. DOWNLOADS THE IMAGE
// function dataURLtoBlob(dataurl) {
//     const arr = dataurl.split(',');
//     const mime = arr[0].match(/:(.*?);/)[1];
//     const bstr = atob(arr[1]);
//     let n = bstr.length;
//     const u8arr = new Uint8Array(n);

//     while (n--) {
//         u8arr[n] = bstr.charCodeAt(n);
//     }
//     return new Blob([u8arr], { type: mime });
// }

const convertAllTextAreasToDivs = (elementId) => {
  const docParent = document.getElementById(elementId);

  // Select only textarea elements within docParent
  const textAreas = Array.from(docParent.getElementsByTagName('textarea'));
  textAreas.forEach((textArea) => {
    const div = document.createElement('div');

    // Copy all attributes from the textarea to the new div
    Array.from(textArea.attributes).forEach((attr) => {
      div.setAttribute(attr.name, attr.value);
    });

    // Convert line breaks and paragraph breaks to <br> and <p> tags with text-align: left
    const lines = textArea.value.split(/\n/);
    const formattedText = lines
      .map((line) => (line.trim() === '' ? '</p><p style="text-align: left;">' : line))
      .join('<br>');
    div.innerHTML = `<p style="text-align: left;">${formattedText}</p>`;

    // If you want the divs to be editable, set contentEditable to true
    div.contentEditable = 'true';

    // Replace the textarea with the new div
    textArea.parentNode.replaceChild(div, textArea);
  });
};

const htmlToPdf = async (elementId) => {
  convertAllTextAreasToDivs(elementId);
  const testForm = document.getElementById(elementId);

  let pdfData = null;

  // eslint-disable-next-line no-undef
  await html2canvas(testForm, { scale: 2 }).then(async (canvas) => {
    const imgData = canvas.toDataURL('image/png', 1);

    const secret = process.env.REACT_APP_CONVERT_API_SECRET;
    const fileContents = utf8.encode(imgData.split(',')[1]);

    // // FOR DEBUGGING PURPOSES. DOWNLOADS THE IMAGE
    // const blob = dataURLtoBlob(imgData);
    // const link = document.createElement('a');
    // link.href = window.URL.createObjectURL(blob);
    // link.download = 'image.png';  // You can change the name here
    // link.click();

    const imgToPdfPostBody = {
      Parameters: [
        {
          Name: 'File',
          FileValue: {
            Name: 'my_file.png',
            Data: fileContents,
          },
        },
        {
          Name: 'StoreFile',
          Value: false,
        },
        {
          Name: 'ColorSpace',
          Value: 'gray',
        },
        {
          Name: 'MarginHorizontal',
          Value: '13',
        },
        {
          Name: 'MarginVertical',
          Value: '13',
        },
      ],
    };

    const imgToPdfResponse = await postAsync3rdParty(
      `https://v2.convertapi.com/convert/png/to/pdf?Secret=${secret}`,
      imgToPdfPostBody,
    );

    // const pdfToPdfWithTextPostBody = {
    //   Parameters: [
    //     {
    //       Name: 'File',
    //       FileValue: {
    //         Name: 'my_file.pdf',
    //         Data: imgToPdfResponse.data.Files[0].FileData,
    //       },
    //     },
    //     {
    //       Name: 'StoreFile',
    //       Value: false,
    //     },
    //   ],
    // };

    // const pdfToPdfWithTextResponse = await postAsync3rdParty(
    //   `https://v2.convertapi.com/convert/pdf/to/ocr?Secret=${secret}`,
    //   pdfToPdfWithTextPostBody,
    // );

    // pdfData = pdfToPdfWithTextResponse.data.Files[0].FileData;
    pdfData = imgToPdfResponse.data.Files[0].FileData;
  });

  return pdfData;
};

export default htmlToPdf;
