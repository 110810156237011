import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react';
import { Card, CardBody, CardTitle, Row, Col, FormGroup, Label, FormText } from 'reactstrap';
import { Formik, Field, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
// import { useMsal } from '@azure/msal-react';

import htmlToPdf from '../../../functions/my-pdf';
import { ProcessingModal, SuccessModal, ErrorModal } from '../../modals/CustomModals';

const FinalPswForm = forwardRef((props, ref) => {
  //  const { accounts } = useMsal();
  const loggedInUser = JSON.parse(localStorage.getItem('JETChargeCurrentUser'))?.name;
  const [contractorMode] = useState(window.location.pathname.includes('/contractors/'));
  const [customerMode] = useState(window.location.pathname.includes('/customers/'));

  const [initialValues] = useState({
    ContractorInclusions: props.initialValues.ContractorInclusions
      ? JSON.parse(props.initialValues.ContractorInclusions)
      : [],
    InstallationScopeOfWorks: props.initialValues.InstallationScopeOfWorks,
    ContractorDetails: props.initialValues.ContractorDetails,
    AdditionalContractors: props.initialValues.AdditionalContractors,
    ClientContractors: props.initialValues.ClientContractors,
    AdditionalArrangements: props.initialValues.AdditionalArrangements,
    Pdf: props.initialValues.Pdf,
    UpdatedBy: loggedInUser,
    DocumentNumber: props.initialValues.DocumentNumber,
  });

  const processingModalRef = useRef();
  const successModalRef = useRef();
  const errorModalRef = useRef();

  let globalFormik = {};

  const validationSchema = Yup.object().shape({
    ContractorInclusions: Yup.array()
      .min(1, 'Contractor Inclusions is required')
      .required('Contractor Inclusions is required'),
    InstallationScopeOfWorks: Yup.string().required('Installation Scope of Works is required'),
    ContractorDetails: Yup.string().required('Contractor Details is required'),
    AdditionalContractors: Yup.string().required(
      'Will PM need to engage additional contractors is required',
    ),
    ClientContractors: Yup.string().required(
      "Will client's own contractors complete any works is required",
    ),
    AdditionalArrangements: Yup.string().required('Additional contractor arrangements is required'),
  });

  const validateForm = async () => {
    let response = {};
    response = await globalFormik.validateForm();

    return response;
  };

  useImperativeHandle(ref, () => ({
    async getInputValues() {
      const pdf = await htmlToPdf('finalPswForm');
      const inputValues = { ...globalFormik.values, Pdf: pdf };

      return inputValues;
    },
    async validateForm() {
      const response = await validateForm();
      return response;
    },
  }));

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={() => {
          // Do nothing. Everything is handled in the handleSubmit function
        }}
      >
        {
          //eslint-disable-next-line no-unused-vars
          (formik) => {
            globalFormik = formik;
            return (
              <>
                <FormikForm id={props.id ?? 'finalPswForm'}>
                  <h1 style={{ textAlign: 'center' }}>{props.title}</h1>
                  {contractorMode || customerMode ? (
                    <Label
                      className="hidePrint"
                      style={{ display: 'block', textAlign: 'left' }}
                      color="muted"
                    >
                      Please confirm the following information is correct
                    </Label>
                  ) : (
                    ''
                  )}
                  <Row>
                    <Col md="12">
                      <Card>
                        <CardTitle
                          tag="h5"
                          className="border-bottom bg-white p-3 mb-0 text-black text-center"
                        >
                          <span style={{ float: 'left' }}>
                            Final Scope of Works / {formik.values.DocumentNumber}
                          </span>
                        </CardTitle>
                        <CardBody>
                          <Row>
                            <Col md="12" style={{ textAlign: 'left' }}>
                              <FormGroup>
                                <Label htmlFor="InstallationScopeOfWorks">
                                  Installation Scope of Works
                                </Label>
                                <FormText
                                  className="hidePrint"
                                  style={{ display: 'block', textAlign: 'left' }}
                                  color="muted"
                                >
                                  (For project delivery team to review and confirm)
                                </FormText>
                                <Field
                                  className="form-control"
                                  component="textarea"
                                  name="InstallationScopeOfWorks"
                                  placeholder=""
                                  rows="3"
                                  style={{ resize: 'none' }}
                                />
                                {formik.errors.InstallationScopeOfWorks ? (
                                  <div
                                    style={{ display: 'block', textAlign: 'left' }}
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.InstallationScopeOfWorks}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12" style={{ textAlign: 'left' }}>
                              <FormGroup>
                                <Label htmlFor="ContractorDetails">Contractor Details</Label>
                                <Field
                                  className="form-control"
                                  component="textarea"
                                  name="ContractorDetails"
                                  placeholder=""
                                  rows="3"
                                  style={{ resize: 'none' }}
                                />
                                {formik.errors.ContractorDetails ? (
                                  <div
                                    style={{ display: 'block', textAlign: 'left' }}
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.ContractorDetails}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <FormGroup style={{ textAlign: 'left' }}>
                              <Label htmlFor="ContractorInclusions">Contractor Inclusions</Label>
                            </FormGroup>
                            {formik.errors.ContractorInclusions ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors.ContractorInclusions}
                              </div>
                            ) : (
                              ''
                            )}
                            <Col md="3" style={{ textAlign: 'left' }}>
                              <FormGroup check>
                                <Field
                                  type="checkbox"
                                  className="form-check-input"
                                  name="ContractorInclusions"
                                  value="mechanicalProtection"
                                />
                                <Label check>Mechanical Protection</Label>
                              </FormGroup>
                            </Col>
                            <Col md="3" style={{ textAlign: 'left' }}>
                              <FormGroup check>
                                <Field
                                  type="checkbox"
                                  className="form-check-input"
                                  name="ContractorInclusions"
                                  value="carParkPainting"
                                />
                                <Label check>Carpark painting</Label>
                              </FormGroup>
                            </Col>
                            <Col md="3" style={{ textAlign: 'left' }}>
                              <FormGroup check>
                                <Field
                                  type="checkbox"
                                  className="form-check-input"
                                  name="ContractorInclusions"
                                  value="concreteSlabs"
                                />
                                <Label check>Concrete slabs</Label>
                              </FormGroup>
                            </Col>
                            <Col md="3" style={{ textAlign: 'left' }}>
                              <FormGroup check>
                                <Field
                                  type="checkbox"
                                  className="form-check-input"
                                  name="ContractorInclusions"
                                  value="wrapping"
                                />
                                <Label check>Wrapping</Label>
                              </FormGroup>
                            </Col>
                            <Col md="3" style={{ textAlign: 'left' }}>
                              <FormGroup check>
                                <Field
                                  type="checkbox"
                                  className="form-check-input"
                                  name="ContractorInclusions"
                                  value="typeBRCD"
                                />
                                <Label check>Type B RCD</Label>
                              </FormGroup>
                            </Col>
                            <Col md="3" style={{ textAlign: 'left' }}>
                              <FormGroup check>
                                <Field
                                  type="checkbox"
                                  className="form-check-input"
                                  name="ContractorInclusions"
                                  value="comms"
                                />
                                <Label check>Comms</Label>
                              </FormGroup>
                            </Col>
                            <Col md="3" style={{ textAlign: 'left' }}>
                              <FormGroup check>
                                <Field
                                  type="checkbox"
                                  className="form-check-input"
                                  name="ContractorInclusions"
                                  value="coreInstallation"
                                />
                                <Label check>Core installation</Label>
                              </FormGroup>
                            </Col>
                            <Col md="3" style={{ textAlign: 'left' }}>
                              <FormGroup check>
                                <Field
                                  type="checkbox"
                                  className="form-check-input"
                                  name="ContractorInclusions"
                                  value="fireSealing"
                                />
                                <Label check>Fire sealing</Label>
                              </FormGroup>
                            </Col>
                          </Row>
                          &nbsp;
                          <Row>
                            <Col md="12" style={{ textAlign: 'left' }}>
                              <FormGroup>
                                <Label htmlFor="AdditionalContractors">
                                  Will PM need to engage additional contractors?
                                </Label>
                                <FormGroup>
                                  <FormGroup check inline>
                                    <Field
                                      className="form-check-input"
                                      type="radio"
                                      name="AdditionalContractors"
                                      value="yes"
                                    />
                                    <Label check>Yes</Label>
                                  </FormGroup>
                                  <FormGroup check inline>
                                    <Field
                                      className="form-check-input"
                                      type="radio"
                                      name="AdditionalContractors"
                                      value="no"
                                    />
                                    <Label check>No</Label>
                                  </FormGroup>
                                </FormGroup>
                                {formik.errors.AdditionalContractors ? (
                                  <div
                                    style={{ display: 'block', textAlign: 'left' }}
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.AdditionalContractors}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12" style={{ textAlign: 'left' }}>
                              <FormGroup>
                                <Label htmlFor="ClientContractors">
                                  Will client&apos;s own contractors complete any works?
                                </Label>
                                <FormGroup>
                                  <FormGroup check inline>
                                    <Field
                                      className="form-check-input"
                                      type="radio"
                                      name="ClientContractors"
                                      value="yes"
                                    />
                                    <Label check>Yes</Label>
                                  </FormGroup>
                                  <FormGroup check inline>
                                    <Field
                                      className="form-check-input"
                                      type="radio"
                                      name="ClientContractors"
                                      value="no"
                                    />
                                    <Label check>No</Label>
                                  </FormGroup>
                                </FormGroup>
                                {formik.errors.ClientContractors ? (
                                  <div
                                    style={{ display: 'block', textAlign: 'left' }}
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.ClientContractors}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12" style={{ textAlign: 'left' }}>
                              <FormGroup>
                                <Label htmlFor="AdditionalArrangements">
                                  Additional contractor arrangements?
                                </Label>
                                <Field
                                  className="form-control"
                                  component="textarea"
                                  name="AdditionalArrangements"
                                  placeholder=""
                                  rows="3"
                                  style={{ resize: 'none' }}
                                />
                                {formik.errors.AdditionalArrangements ? (
                                  <div
                                    style={{ display: 'block', textAlign: 'left' }}
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.AdditionalArrangements}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="6">
                      <ProcessingModal ref={processingModalRef} />
                    </Col>
                    <Col xs="12" md="6">
                      <SuccessModal ref={successModalRef} />
                    </Col>
                    <Col xs="12" md="6">
                      <ErrorModal ref={errorModalRef} />
                    </Col>
                  </Row>
                </FormikForm>
              </>
            );
          }
        }
      </Formik>
    </div>
  );
});

FinalPswForm.propTypes = {
  initialValues: PropTypes.object,
  id: PropTypes.string,
  // fileUploadUrl: PropTypes.string,
  // fileDownloadUrl: PropTypes.string,
  title: PropTypes.string,
};

export default FinalPswForm;
