import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import reportWebVitals from './reportWebVitals';
import { store } from './store/Store';
import App from './app';
import './data';
import { getUserType } from './functions/my-user';

const jetChargeConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    authority: process.env.REACT_APP_AZURE_AUTHORITY_URL,
    redirectUri: process.env.REACT_APP_HOME_URL,
    postLogoutRedirectUri: process.env.REACT_APP_LOGIN_URL,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

const contractorConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CONTRACTOR_CLIENT_ID,
    authority: process.env.REACT_APP_AZURE_CONTRACTOR_AUTHORITY_URL,
    knownAuthorities: [`${process.env.REACT_APP_AZURE_CONTRACTOR_AUTHORITY_URL}`],
    redirectUri: process.env.REACT_APP_CONTRACTOR_HOME_URL,
    postLogoutRedirectUri: '/logout',
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Function to dynamically select configuration
const selectConfiguration = () => {
  const usertype = getUserType();
  if (usertype === 'contractor') {
    return contractorConfig;
  }

  return jetChargeConfig;
};

const fetchTranslations = async () => {
  const resources = {
    en: {
      translation: await import('./locales/en.json'),
    },
    // fr: {
    //   translation: await import('./locales/fr.json'),
    // },
    // // Add more languages as needed
  };

  return Object.keys(resources).reduce((acc, lang) => {
    acc[lang] = { translation: resources[lang].translation.default };
    return acc;
  }, {});
};

fetchTranslations().then((i18nResources) => {
  i18n.init({
    interpolation: { escapeValue: false }, // Allows using HTML tags in translation strings
    lng: 'en', // Default language
    resources: i18nResources,
  });

  const pca = new PublicClientApplication(selectConfiguration());

  const container = document.getElementById('root');
  const root = createRoot(container);

  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <MsalProvider instance={pca}>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </MsalProvider>
      </BrowserRouter>
    </Provider>,
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
