import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Card, CardBody, CardTitle, Row, Col, Label, Collapse, FormGroup } from 'reactstrap';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Enumerable from 'linq';
import * as Icon from 'react-feather';

import {
  ProcessingModal,
  SuccessModal,
  ErrorModal,
  ConfirmationModal,
} from '../../modals/CustomModals';
import UppyFileUploader from '../../../layouts/uppy/uppy';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const QuoteUploadForm = forwardRef((props, ref) => {
  const processingModalRef = React.useRef();
  const successModalRef = React.useRef();
  const errorModalRef = React.useRef();
  const quoteFilesRef = React.useRef();
  const quoteRejectModalRef = React.useRef();
  const commentsRef = React.useRef();

  const [collapse, setCollapse] = useState(props.isOpen ?? true);
  const [contractorMode] = useState(window.location.pathname.includes('/contractors/'));
  const [customerMode] = useState(window.location.pathname.includes('/customers/'));

  let globalFormik = {};

  const initialValues = {
    quoteFiles: props?.initialValues?.Quotes,
    Comments: props?.initialValues?.Data
      ? JSON.parse(props?.initialValues?.Data)?.ContractorComments
      : '',
  };

  const validationSchema = Yup.object().shape({
    // quoteFiles: Yup.string()
    //   .when('', {
    //     is: () => quoteFilesRef.current.isEmpty(),  // Check if value is not 'yes'
    //     then: Yup.string().required('Required'),
    //     otherwise: Yup.string()
    //   }),
  });

  const validateForm = async () => {
    let validationResponse = await globalFormik.validateForm();
    const isQuoteEmpty = await quoteFilesRef.current.isEmpty();

    if (isQuoteEmpty) {
      validationResponse = { ...validationResponse, quoteFiles: 'Quote is required' };
      globalFormik.setFieldError('quoteFiles', 'Quote is required');
    }

    return validationResponse;
  };

  const toggleCollapse = () => {
    setCollapse(!collapse);
  };

  const autoResize = (e) => {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  useImperativeHandle(ref, () => ({
    async validateForm() {
      const response = await validateForm();
      return response;
    },
    async getRejectReason() {
      const rejectReason = await quoteRejectModalRef.current.getCommentBody();
      return rejectReason;
    },
    async getFormikValues() {
      const values = await globalFormik.values;

      // Override the properties
      values.Data = JSON.stringify({
        ContractorComments: values.Comments,
        TsaComments: quoteRejectModalRef.current.getCommentBody(),
      });
      return values;
    },
  }));

  useEffect(() => {
    // Set the height to 'auto' to correctly calculate scrollHeight
    if (commentsRef.current) {
      commentsRef.current.style.height = 'auto';
      // Set the height to scrollHeight
      commentsRef.current.style.height = `${commentsRef.current.scrollHeight}px`;
    }
  }, []);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={() => {
          // Do nothing. Everything is handled in the handleSubmit function
        }}
      >
        {
          //eslint-disable-next-line no-unused-vars
          (formik) => {
            globalFormik = formik;
            return (
              <>
                <Row>
                  <Col md="12">
                    <Card>
                      <CardTitle
                        tag="h5"
                        className="border-bottom bg-white p-3 mb-0 text-black text-center card-rounded-top"
                        onClick={toggleCollapse.bind(null)}
                      >
                        <span style={{ float: 'left' }}>{props.title}</span>
                        {collapse ? (
                          <Icon.Minus size={30} style={{ float: 'right' }} />
                        ) : (
                          <Icon.Plus size={30} style={{ float: 'right' }} />
                        )}
                      </CardTitle>
                      <Collapse isOpen={collapse}>
                        <CardBody className="card-rounded-bottom">
                          {contractorMode || customerMode ? (
                            <Label for="quoteFiles">Please attach or drop a quote here</Label>
                          ) : (
                            ''
                          )}
                          <Row>
                            <Col md="12">
                              <UppyFileUploader
                                id="quoteFiles"
                                uploadUrl={props.fileUploadUrl}
                                downloadUrl={props.fileDownloadUrl}
                                existingFiles={Enumerable.from(props.initialValues?.Quotes ?? [])
                                  .where((x) => x.PhotoFor === 'quoteFiles')
                                  .toArray()}
                                description="Data Logging Output"
                                ref={quoteFilesRef}
                                disabled={props.disabled}
                              />
                              {formik.errors.quoteFiles ? (
                                <div
                                  style={{ display: 'block', textAlign: 'left' }}
                                  className="invalid-feedback"
                                >
                                  {formik.errors.quoteFiles}
                                </div>
                              ) : (
                                ''
                              )}
                            </Col>
                          </Row>
                          &nbsp;
                          <Row>
                            <Col md="12">
                              <FormGroup>
                                <Label htmlFor="Comments">Comments</Label>
                                <Field
                                  innerRef={commentsRef}
                                  className="form-control"
                                  component="textarea"
                                  name="Comments"
                                  placeholder=""
                                  value={formik.values.Comments}
                                  rows="3"
                                  style={{ resize: 'none' }}
                                  disabled={props.disabled}
                                  onChange={(e) => {
                                    formik.setFieldValue('Comments', e.target.value);
                                    autoResize(e);
                                  }}
                                />
                                {formik.errors.Comments ? (
                                  <div
                                    style={{ display: 'block', textAlign: 'left' }}
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.Comments}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="3">
                              {props.showRejectButton ? (
                                <input
                                  type="button"
                                  className="btn btn-primary mb-3"
                                  style={{ width: '135px', fontSize: '12px' }}
                                  value={props.rejectLabel}
                                  onClick={() => {
                                    quoteRejectModalRef.current.toggleModal();
                                  }}
                                />
                              ) : (
                                ''
                              )}
                              &nbsp;
                              {props.showApproveButton ? (
                                <input
                                  type="button"
                                  className="btn btn-primary mb-3"
                                  style={{ width: '135px', fontSize: '12px' }}
                                  value={props.approveLabel}
                                  onClick={() => {
                                    props.approveCallback();
                                  }}
                                />
                              ) : (
                                ''
                              )}
                            </Col>
                          </Row>
                        </CardBody>
                      </Collapse>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="6">
                    <ProcessingModal ref={processingModalRef} />
                  </Col>
                  <Col xs="12" md="6">
                    <SuccessModal ref={successModalRef} />
                  </Col>
                  <Col xs="12" md="6">
                    <ErrorModal ref={errorModalRef} />
                  </Col>
                  <Col xs="12" md="6">
                    <ConfirmationModal
                      ref={quoteRejectModalRef}
                      submitCallBack={() => {
                        props.rejectCallback();
                      }}
                      body="You are about to reject the quotation. Please enter a rejection reason"
                      showCommentBox
                      confirmText="Confirm"
                      cancelText="Cancel"
                      formikProps={formik}
                    />
                  </Col>
                </Row>
              </>
            );
          }
        }
      </Formik>
    </div>
  );
});

QuoteUploadForm.propTypes = {
  initialValues: PropTypes.object,
  fileUploadUrl: PropTypes.string,
  fileDownloadUrl: PropTypes.string,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  approveCallback: PropTypes.func,
  rejectCallback: PropTypes.func,
  rejectLabel: PropTypes.string,
  approveLabel: PropTypes.string,
  showRejectButton: PropTypes.bool,
  showApproveButton: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default QuoteUploadForm;
