import React, { useState, useCallback, useEffect } from 'react';
import { FormText } from 'reactstrap';
import PropTypes from 'prop-types';
import _ from 'lodash'; // Import Lodash
import { getAsync } from '../../functions/my-api';

const SimProQuoteLookup = (props) => {
  const [inputValue, setInputValue] = useState(props.initialValue ?? '');
  const [quoteDetails, setQuoteDetails] = useState([]); // Suggestions from API call

  const QUOTE_DETAILS_URL = `${process.env.REACT_APP_BASEAPI_URL}/simpro/quote`;

  // Debounced API call
  const fetchSuggestions = useCallback(
    _.debounce(async (value) => {
      const url = `${QUOTE_DETAILS_URL}/${value} `;
      const response = await getAsync(url);

      if (response.data && response.data?.Name && response.data?.Customer?.CompanyName) {
        setQuoteDetails({
          name: response.data?.Name,
          customer: response.data?.Customer?.CompanyName,
        });
      } else {
        setQuoteDetails({ name: '', customer: '' });
      }
    }, 1000),
    [],
  ); // Only re-create if dependencies change

  useEffect(() => {
    if (props.initialValue) {
      fetchSuggestions(props.initialValue);
    }
  }, []);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    fetchSuggestions(e.target.value);

    if (props.changeCallBack) {
      props.changeCallBack(e.target.value);
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <input
        className="form-control"
        type="text"
        value={inputValue}
        disabled={props.disabled}
        onChange={handleInputChange}
      />
      <div style={{ textAlign: 'left' }}>
        <FormText color="muted">Name: {quoteDetails.name}</FormText>
        <br />
        <FormText color="muted">Customer: {quoteDetails.customer}</FormText>
      </div>
    </div>
  );
};

SimProQuoteLookup.propTypes = {
  changeCallBack: PropTypes.func,
  initialValue: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SimProQuoteLookup;
