import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';

export const useUserPhoto = () => {
  const { instance, accounts } = useMsal();
  const account = accounts[0]; // Assuming there is an account
  const [photoUrl, setPhotoUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorGettingPhoto, setErrorGettingPhoto] = useState(false);

  const resetUserPhoto = () => {
    localStorage.removeItem('JETChargeCurrentUserPhoto');
    setPhotoUrl(null);
    setIsLoading(true);
  };

  useEffect(() => {
    const getUserPhoto = async () => {
      try {
        // If there's already a photo URL in session storage, use it
        const savedPhotoUrl = localStorage.getItem('JETChargeCurrentUserPhoto');
        if (savedPhotoUrl) {
          setPhotoUrl(savedPhotoUrl);
          setIsLoading(false);
          setErrorGettingPhoto(false);
        } else {
          // Otherwise, fetch a new photo URL
          const authResult = await instance.acquireTokenSilent({
            scopes: ['User.Read'],
            account,
          });

          const photoResponse = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
            headers: {
              Authorization: `Bearer ${authResult.accessToken}`,
            },
          });

          if (photoResponse.ok) {
            const imageBlob = await photoResponse.blob();
            const imageURL = URL.createObjectURL(imageBlob);
            localStorage.setItem('JETChargeCurrentUserPhoto', imageURL); // Save it in session storage
            setPhotoUrl(imageURL);
          } else if (photoResponse.status === 404) {
            // Handle the case where there is no photo available
          }
        }
      } catch (e) {
        console.error('Error fetching photo', e);
        setErrorGettingPhoto(true);
        // Handle the error state as well, if needed
      } finally {
        setIsLoading(false);
      }
    };

    if (isLoading) {
      getUserPhoto();
    }
  }, [instance, account, isLoading]);

  return [photoUrl, isLoading, errorGettingPhoto, resetUserPhoto];
};

export const getUserType = () => {
  if (window.location.pathname.includes('contractor')) {
    localStorage.setItem('JETChargeUserType', 'contractor');
  } else if (window.location.pathname.includes('customer')) {
    localStorage.setItem('JETChargeUserType', 'customer');
  } else if (window.location.pathname.includes('internal')) {
    localStorage.setItem('JETChargeUserType', 'internal');
  }

  return localStorage.getItem('JETChargeUserType');
};
