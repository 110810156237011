import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentlyLoggedInUser: null,
};

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    SetCurrentlyLoggedInUser: (state, action) => {
      state.currentlyLoggedInUser = action.payload;
    },
  },
});

export const { SetCurrentlyLoggedInUser } = UserSlice.actions;

export default UserSlice.reducer;
