import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react';
import { Formik, Field } from 'formik';
import { Card, CardBody, CardTitle, Row, Col, FormGroup, Label, FormText, Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import * as Icon from 'react-feather';
import TooltipItem from './Tooltip';

const ContactDetails = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(true);
  const [initialValues] = useState({
    [`Name_${props.index}`]: props.values ? props.values[`Name_${props.index}`] : '',
    [`Role_${props.index}`]: props.values ? props.values[`Role_${props.index}`] : '',
    [`MobileNumberCountry_${props.index}`]: props.values
      ? props.values[`MobileNumberCountry_${props.index}`]
      : '',
    [`MobileNumber_${props.index}`]: props.values
      ? props.values[`MobileNumber_${props.index}`]
      : '',
    [`Email_${props.index}`]: props.values ? props.values[`Email_${props.index}`] : '',
    visible: true,
  });

  const [validationSchema] = useState(
    Yup.object({
      [`Name_${props.index}`]: Yup.string().required(
        `Contact Name ${props.index === 1 ? '' : props.index} is required`,
      ),
      [`Role_${props.index}`]: Yup.string().required(
        `Contact Role ${props.index === 1 ? '' : props.index} at Company is required`,
      ),
      [`MobileNumber_${props.index}`]: Yup.string().required(
        `Contact Mobile Number ${props.index === 1 ? '' : props.index} is required`,
      ),
      [`Email_${props.index}`]: Yup.string().required(
        `Contact Email Address ${props.index === 1 ? '' : props.index} is required`,
      ),
    }),
  );

  const formikRef = useRef();

  const handleSameAsClientDetails = (e, formik) => {
    if (e.target.checked) {
      formik.setFieldValue(`Name_${props.index}`, document.getElementById('MainContact').value);
      formik.setFieldValue(`Role_${props.index}`, document.getElementById('MainContactRole').value);
      formik.setFieldValue(`Email_${props.index}`, document.getElementById('ClientEmail').value);
      formik.setFieldValue(
        `MobileNumberCountry_${props.index}`,
        document.getElementById('ClientMobileNumberCountry').value,
      );
      formik.setFieldValue(
        `MobileNumber_${props.index}`,
        document.getElementById('ClientMobileNumber').value,
      );
    }
  };

  useImperativeHandle(ref, () => ({
    validateForm: async () => {
      const validationErrors = await formikRef.current.validateForm();
      return validationErrors;
    },
    getInputValues: () => {
      const temp = formikRef.current?.values;

      // seems like formik is not getting the value from the dropdown, so we are getting it manually
      temp[`MobileNumberCountry_${props.index}`] = document.getElementById(
        `MobileNumberCountry_${props.index}`,
      ).value;
      return temp;
    },
    hideEverything: () => {
      formikRef.current.values.visible = false;
      setVisible(false);
    },
    isVisible: () => {
      return formikRef.current.values?.visible;
    },
  }));

  return (
    <div>
      {visible ? (
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={() => {
            // Do nothing. Everything is handled in the handleSubmit function
          }}
        >
          {(formik) => {
            return (
              <Row>
                <Col md="12">
                  <Card>
                    <CardTitle
                      tag="h5"
                      className="border-bottom bg-white p-3 mb-0 text-black text-center card-rounded-top"
                    >
                      <span style={{ float: 'left' }}>
                        {props.showLabelTitle ? props.showLabelTitle : 'Contact Details'}
                        {props.index === 1 ? '' : props.index}
                      </span>
                      <Icon.HelpCircle
                        id="toolTip-contactDetails"
                        size={20}
                        style={{ color: 'blue', float: 'left', marginLeft: '10px' }}
                      />
                      <TooltipItem target="toolTip-contactDetails" placement="top">
                        <div>
                          <p style={{ textAlign: 'left' }}>
                            Alongside main client contact, please include contact details of the
                            Facilities Manager, or contact who has access to the site including keys
                            and ideally basic knowledge of the services
                          </p>
                        </div>
                      </TooltipItem>
                      {props.showAddContactButton ? (
                        <Badge
                          color="primary"
                          pill
                          style={{ float: 'right', zIndex: 999, cursor: 'pointer' }}
                          onClick={props.addContactCallback}
                        >
                          Add Contact <Icon.FilePlus size={20} />
                        </Badge>
                      ) : (
                        ''
                      )}
                    </CardTitle>
                    <CardBody className="card-rounded-bottom">
                      {props.showSameAsClient ? (
                        <>
                          <Row>
                            <Col md="6">
                              <FormGroup check style={{ textAlign: 'left' }}>
                                <Field
                                  className="form-check-input"
                                  type="checkbox"
                                  id="SameAsClientDetails"
                                  name="SameAsClientDetails"
                                  onChange={(e) => {
                                    formik.handleChange(e);
                                    handleSameAsClientDetails(e, formik);
                                  }}
                                />
                                <FormText color="secondary">Same as Client Details</FormText>
                              </FormGroup>
                            </Col>
                          </Row>
                          <hr />
                        </>
                      ) : (
                        ''
                      )}
                      <Row>
                        <Col md="6" style={{ textAlign: 'left' }}>
                          <FormGroup>
                            <Label htmlFor={`Name_${props.index}`}>Contact Name</Label>
                            <Field
                              name={`Name_${props.index}`}
                              id={`Name_${props.index}`}
                              className="form-control"
                              value={formik.values[`Name_${props.index}`]}
                            />
                            {formik.errors[`Name_${props.index}`] ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors[`Name_${props.index}`]}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="6" style={{ textAlign: 'left' }}>
                          <FormGroup>
                            <Label htmlFor={`Role_${props.index}`}>Contact Role at Company</Label>
                            <Field
                              name={`Role_${props.index}`}
                              id={`Role_${props.index}`}
                              className="form-control"
                              value={formik.values[`Role_${props.index}`]}
                            />
                            {formik.errors[`Role_${props.index}`] ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors[`Role_${props.index}`]}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" style={{ textAlign: 'left' }}>
                          <FormGroup>
                            <Label htmlFor={`MobileNumberCountry_${props.index}`}>
                              Contact Mobile Number
                            </Label>
                            <div style={{ display: 'flex' }}>
                              <Field
                                as="select"
                                className="form-select"
                                id={`MobileNumberCountry_${props.index}`}
                                name={`MobileNumberCountry_${props.index}`}
                                onChange={formik.handleChange}
                                style={{ width: '100px', marginRight: '10px' }}
                              >
                                <option value="+61">+61</option>
                                <option value="+64">+64</option>
                              </Field>
                              <Field
                                name={`MobileNumber_${props.index}`}
                                id={`MobileNumber_${props.index}`}
                                className="form-control"
                                value={formik.values[`MobileNumber_${props.index}`]}
                                style={{ width: '200px', marginRight: 0 }}
                              />
                            </div>
                            {formik.errors[`MobileNumber_${props.index}`] ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors[`MobileNumber_${props.index}`]}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="6" style={{ textAlign: 'left' }}>
                          <FormGroup>
                            <Label htmlFor={`Email_${props.index}`}>Contact Email Address</Label>
                            <Field
                              name={`Email_${props.index}`}
                              id={`Email_${props.index}`}
                              className="form-control"
                              value={formik.values[`Email_${props.index}`]}
                            />
                            {formik.errors[`Email_${props.index}`] ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors[`Email_${props.index}`]}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12" style={{ textAlign: 'left' }}>
                          {props.showRemoveButton ? (
                            <Badge
                              color="danger"
                              pill
                              style={{ zIndex: 999, cursor: 'pointer' }}
                              onClick={props.removeButtonCallback}
                            >
                              Remove <Icon.Minus size={20} />
                            </Badge>
                          ) : (
                            ''
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            );
          }}
        </Formik>
      ) : (
        ''
      )}
    </div>
  );
});

ContactDetails.propTypes = {
  showAddContactButton: PropTypes.bool,
  addContactCallback: PropTypes.func,
  index: PropTypes.number,
  values: PropTypes.object,
  showRemoveButton: PropTypes.bool,
  removeButtonCallback: PropTypes.func,
  showSameAsClient: PropTypes.bool,
  showLabelTitle: PropTypes.string,
};

export default ContactDetails;
